import React, { useEffect } from "react"
import Section from "wildComponents/Section"
import debounce from "lodash.debounce"
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  HStack,
  Image,
  Text,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
  ListItem,
  OrderedList,
  Input,
  Link,
} from "@chakra-ui/react"
import { AddIcon } from "@chakra-ui/icons"

function Activities({ data }) {
  const [activities, setActivities] = React.useState(data?.nodes || [])
  const categoriesColumn = Array.from(
    new Set(activities.map(node => node.activityConsistGroup))
  ).sort()

  function filterActivities(event) {
    const searchString = event.target.value
    if (!!searchString && searchString.length > 0) {
      setActivities(
        data.nodes.filter(
          node =>
            node.activityName
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            node.activityDescription
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            node.activityConsistGroup
              .toLowerCase()
              .includes(searchString.toLowerCase())
        )
      )
    } else {
      setActivities(data.nodes)
    }
  }

  const debouncedChangeHandler = React.useMemo(
    () => debounce(filterActivities, 300),
    []
  )

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel()
    }
  }, [debouncedChangeHandler])

  return (
    <Section minH="100vh" containerSize="xl" className="light">
      <Heading mb={8}>All Business Activities</Heading>
      <Input
        onChange={debouncedChangeHandler}
        variant="outline"
        mb={8}
        size="lg"
        bg="#f6f6f6"
        placeholder="Search Activities"
      />
      <Box borderRadius={["lg", "lg", "30px"]} overflow="hidden" w="full">
        <Tabs
          w="full"
          bg="#f6f6f6"
          p={8}
          display={["none", "none", "flex"]}
          maxH={["100vh", "100vh", "70vh"]}
        >
          <TabList
            display="flex"
            flexDirection="column"
            wrap="nowrap"
            border="none"
            overflowY="scroll"
            sx={{
              "button[aria-selected='true']": {
                bg: "white !important",
                img: {
                  filter: "grayscale(0)",
                  opacity: 1,
                  transform: "scale(1)",
                },
              },
            }}
          >
            {categoriesColumn.map((item, i) => {
              return (
                <Tab
                  w="100%"
                  border="none"
                  key={`tab-${i}`}
                  py={5}
                  px={8}
                  _hover={{ bg: "dark.100" }}
                  _focus={{ dropShadow: "none", border: "none" }}
                >
                  <HStack w="full" justify="flex-start">
                    <Image
                      h="50px"
                      src="https://res.cloudinary.com/wild-creative/image/upload/v1653637116/DUQE/Vectors/Society_ojksff.svg"
                      filter="grayscale(100%)"
                      opacity={0.3}
                      transform="scale(0.8)"
                      transition="all 0.3s easeInOut"
                    />
                    <Text textAlign="left">{item}</Text>
                  </HStack>
                </Tab>
              )
            })}
          </TabList>

          <TabPanels bg="white" px={8} overflowY="scroll">
            {categoriesColumn.map((item, i) => {
              return (
                <TabPanel w="full" key={`panel-${i}`}>
                  <Accordion allowToggle>
                    {activities
                      .filter(activity => {
                        return activity.activityConsistGroup === item
                      })
                      .map((a, i) => {
                        return i <= 7 ? (
                          <AccordionItem key={`accordion-${i}`}>
                            <AccordionButton
                              h="94px"
                              sx={{
                                "&[aria-expanded='true']": {
                                  bg: "blackAlpha.50",
                                },
                              }}
                            >
                              <Box flex="1" textAlign="left">
                                {a.activityName}
                              </Box>
                              <AccordionIcon
                                icon={<AddIcon fontSize="12px" />}
                              />
                            </AccordionButton>

                            <AccordionPanel
                              pb={12}
                              px={12}
                              fontStyle="italic"
                              fontSize="sm"
                              bg="blackAlpha.50"
                            >
                              {a.activityDescription}
                            </AccordionPanel>
                          </AccordionItem>
                        ) : (
                          <></>
                        )
                      })}
                  </Accordion>
                </TabPanel>
              )
            })}
          </TabPanels>
        </Tabs>
        <Accordion
          allowToggle
          display={["block", "block", "none"]}
          w="full"
          bg="#f6f6f6"
        >
          {categoriesColumn.map((category, i) => {
            return (
              <AccordionItem key={`mobile-acc-${i}`}>
                <AccordionButton h={[20]}>
                  <Box flex="1" textAlign="left">
                    {category}
                  </Box>
                  <AccordionIcon icon={<AddIcon fontSize="12px" />} />
                </AccordionButton>

                <AccordionPanel py={8} pl={8} bg="white">
                  <OrderedList spacing={4}>
                    {activities
                      .filter(activity => {
                        return activity.activityConsistGroup === category
                      })
                      .map((a, i) => {
                        return (
                          <ListItem
                            key={`${a.activityName}-${i}`}
                            fontSize="sm"
                          >
                            {a.activityName}
                          </ListItem>
                        )
                      })}
                  </OrderedList>
                </AccordionPanel>
              </AccordionItem>
            )
          })}
        </Accordion>
      </Box>
      <Text pt={4}>
        *
        <em>
          <Link href="mailto:mailto:info@duqe.ae">Let us know</Link> if you
          can't find the activity you're looking for and we'll get it sorted
        </em>
      </Text>
    </Section>
  )
}

export default Activities
