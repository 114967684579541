import React from "react"
import Layout from "wildComponents/Layout"
import { Seo } from "wildComponents/seo/components"
import { graphql } from "gatsby"
import Hero from "../sections/page-specific/business-activities/Hero"
import Choose from "../sections/page-specific/business-activities/Choose"
import Activities from "../sections/page-specific/business-activities/Activities"
import DarkGroup from "../components/common/DarkGroup"

export const Head = ({ location, data, pageContext }) => (
  <Seo ctx={pageContext} data={data} location={location} />
)

function BusinessActivities(props) {
  return (
    <Layout>
      <DarkGroup>
        <Hero />
        <Choose />
      </DarkGroup>
      <Activities data={props.data?.allGoogleFinalDuqeActivitiesSheet} />
    </Layout>
  )
}

export default BusinessActivities

export const pageQuery = graphql`
  query ($id: String!) {
    allGoogleFinalDuqeActivitiesSheet {
      nodes {
        activityCode
        activityConsistGroup
        activityDescription
        activityMasterGroup
        activityName
        parent {
          id
        }
        id
      }
    }
    wpPage(id: { eq: $id }) {
      title
    }
  }
`
