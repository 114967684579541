import React from "react"
import { Heading, Stack, Text, VStack } from "@chakra-ui/react"
import Section from "wildComponents/Section"

function Choose() {
  return (
    <Section bg="dark.default" className="light">
      <Stack
        spacing={[12, 24, 24]}
        direction={["column", "column", "row"]}
        justify="center"
      >
        <Heading className="jumbo" flex="2" color="white">
          FREEDOM TO CHOOSE FROM{" "}
          <Text as="span" color="brandYellow.default">
            THOUSANDS
          </Text>{" "}
          OF ACTIVITIES
        </Heading>
        <VStack flex="1" align="flex-start" spacing={[8, 8, 12]}>
          <Heading as="h3" className="thin-h3" color="white">
            One of the first steps you'll need to take is to choose your
            business activity. Don't worry, though, because we've got you. The
            process is super simple (and you can even select multiple
            activities).
          </Heading>
        </VStack>
      </Stack>
    </Section>
  )
}

export default Choose
